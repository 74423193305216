<template>
  <div class="container" v-if="this.dataReady">
   <!-- <div
      class="test-area"
      style="
        background-color: aliceblue;
        border: 1px solid gray;
        padding: 10px;
        margin-bottom: 20px;
      "
    >
     <strong>Mit Sicherheit</strong>
      <button class="" @click="getMyResultsSecurity()">
        Ergebnisse abrufen
      </button>
      <div>Assignment 1</div>
      <input
        id="security-assign1-results"
        type="text"
        class="form-control"
        aria-label="Sizing example input"
        aria-describedby="inputGroup-sizing-sm"
        readonly
        style="background-color: #e0e0e0"
      />
      <div>Assignment 2</div>
      <input
        id="security-assign2-results"
        type="text"
        class="form-control"
        aria-label="Sizing example input"
        aria-describedby="inputGroup-sizing-sm"
        readonly
        style="background-color: #e0e0e0"
      /> 
    </div>-->
    <h1>Mit Sicherheit</h1>
    
    
    <div class="row">
      <p>
        Das Experimentiermodul  „Mit Sicherheit” hat Ihnen gezeigt, wie in der
        Informatik vorgegangen wird, um Nachrichten sicher zu versenden. Dabei
        haben Sie kennengelernt, welche zwei grundlegenden Ver- und
        Entschlüsselungsmethoden existieren und welche Herausforderungen mit der
        Verschlüsselung von Nachrichten verbunden sind.
      </p>
      <p>
        Sie haben die Bearbeitung des Experiments “Mit Sicherheit” am
        {{ this.returnDate(this.mySecurityResultsA2.endTime) }} um {{ this.returnTime(this.mySecurityResultsA2.endTime) }} abgeschlossen.
      </p>
      <p>
        Eine detaillierte Darstellung Ihrer Bearbeitung finden Sie in den
        Ergebnissen der beiden Aufgaben unten.
      </p>
    </div>

    <div class="row">
      <h2>Aufgabe: Das Versteck</h2>
      <p>
        Ihre Aufgabe im ersten Teil des Experiments war es, eine mittels
        symmetrischer Verschlüsselung unlesbar gemachte Nachricht, wieder lesbar
        zu machen. Sie haben dabei gesehen, wie einfach es ist, eine solche
        Verschlüsselungsmethode zu „knacken“  und dass solche Methoden in der
        Praxis oft nicht den gewünschten Sicherheitsaspekt bieten können.
      </p>
      <p>
        Zu Beginn des Beispiels war der verschlüsselte Text dabei der folgende:
      </p>
      

      <p class = "sentence">
        <span v-for = "(word, index) in text.sentence" class = "word" :key = "index">
          <template v-for = "(letter, index) in word" :key = "index">
            <span v-if = "text.letters[letter].isDecoded" class = "letter">
                {{ letter }}
            </span>
            <span v-else
                  class = "letter"
                  :class="{'encoded': !text.letters[letter].isDecoded,
                          [text.letters[letter].encoded]: true,
                          'replaced': text.letters[letter].replacement}">
              {{ text.letters[letter].replacement
                ? text.letters[letter].replacement
                : text.letters[letter].encoded }}
            </span>
          </template>
        </span>
      </p>

      <table class="table" v-if="false">
      <thead>
        <tr>
          <th scope="col">Schritt</th>
          <th scope="col">Verschlüsselter Buchstabe</th>
          <th scope="col">Verwendeter Buchstabe</th>
          <th scope="col">Entschlüsselter Text</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th scope="row">0</th>
          <td>-</td>
          <td>-</td>
          <td :style = "{backgroundColor: getBackgroundColor(mySortingResultsA1.firstQuarter.wrongActions.wrongFix)}">{{ mySortingResultsA1.firstQuarter.wrongActions.wrongFix }}</td>
        </tr>
        <tr>
          <th scope="row">2. Viertel</th>
          <td :style = "{backgroundColor: getBackgroundColor(mySortingResultsA1.secondQuarter.wrongActions.wrongRead)}">{{ mySortingResultsA1.secondQuarter.wrongActions.wrongRead }}</td>
          <td :style = "{backgroundColor: getBackgroundColor(mySortingResultsA1.secondQuarter.wrongActions.wrongSave)}">{{ mySortingResultsA1.secondQuarter.wrongActions.wrongSave }}</td>
          <td :style = "{backgroundColor: getBackgroundColor(mySortingResultsA1.secondQuarter.wrongActions.wrongFix)}">{{ mySortingResultsA1.secondQuarter.wrongActions.wrongFix }}</td>
          <td :style = "{backgroundColor: getBackgroundColor(mySortingResultsA1.secondQuarter.wrongActions.wrongSwap)}">{{ mySortingResultsA1.secondQuarter.wrongActions.wrongSwap }}</td>
        </tr>
      </tbody>
    </table>
  <p>Manche Buchstaben waren schon grün hinterlegt, diese waren dann die 
     korrekten Buchstaben. Die restlichen Buchstaben waren grau hinterlegt, 
     welche durch die symmetrische Verschlüsselung wieder lesbar gemacht 
     werden sollten.</p>  

     <p>Im unteren Bereich hatten Sie die Möglichkeit, die noch nicht grün belegten 
       Buchstaben zu ersetzen. Das Feedback war gleich da: Wenn der Buchstabe 
       korrekt war, wurden die entsprechenden grau hinterlegten Buchstaben im 
       oberen Satz durch die richtigen mit einer grünen Hinterlegung ersetzt. Aber 
       wenn er falsch war, wurden die entsprechenden grau hinterlegten Buchstaben 
       im oberen Satz durch die falschen mit einer roten Hinterlegung ersetzt.</p>
    <p>Sie konnten den verschlüsselten Text in {{ this.mySecurityResultsA1.actions.length }} Schritten lösen und haben dabei {{ this.mySecurityResultsA1.actions.length - 6}} mal einen falschen Schlüssel zum Entschlüsseln verwendet. Der korrekt entschlüsselte Satz lautet:</p>
  
    <p class = "sentence">
      <span v-for = "(word, index) in text.sentence" class = "word" :key = "index">
        <template v-for = "(letter, index) in word" :key = "index">
          <span v-if = "text.letters[letter].isDecoded" class = "letter">
              {{ letter }}
          </span>
          <span v-else
                class = "letter"
                :class="{'decoded': !text.letters[letter].isDecoded,
                        [text.letters[letter].encoded]: true,
                        'replaced': text.letters[letter].replacement}">
            {{ text.letters[letter].decoded }}
          </span>
        </template>
      </span>
    </p>
  <p>Nach dieser Aufgabe haben Sie zusätzliche Informationen über 
    asymmetrische Verschlüsselung mit privaten und öffentlichen Schlüsseln 
    erhalten, bevor Sie im Tutorial anhand eines Beispiels diese auch ausprobiert 
    haben. </p>
  </div>

    <div class="row">
      <h2>Aufgabe: Geheimniskrämer:innen</h2>
      <p>
        In der zweiten Aufgabe des Experiments „Mit Sicherheit“  haben Sie die
        Funktionsweise von asymmetrischer Verschlüsselung kennengelernt. Hier
        haben Sie gesehen, dass eine solche Verschlüsselungsmethode zwar etwas
        komplizierter zu erstellen ist, aber auch mehr Sicherheit beim
        Nachrichtenaustausch bietet. Dabei war es Ihre Aufgab, die öffentlichen
        Schlüssel innerhalb einer Gruppe von Personen so zu verteilen, dass
        diese untereinander sicher kommunizieren können. Unten sehen Sie nochmal die endgültige Verteilung der Schlüssel.
      </p>
      <div style="display: flex; justify-content: center">
      <img
        style="min-width: 300px; height: auto;"
        :src="securityA2ExampleImg"
        alt="Mit Sicherheit: Lösung für die Aufgabe 2"
      />
    </div>
    <p></p>
      <p>
        Für die Bearbeitung dieser Aufgabe haben Sie {{ this.mySecurityResultsA2.neededTimeSeconds }} Sekunden gebraucht und
        dabei insgesamt {{ this.mySecurityResultsA2.totalErrors }} Fehler gemacht bevor Sie die korrekte Lösung
        erarbeitet hatten. Ihre Fehler beim Verteilen der öffentlichen Schlüssel
        verteilen sich wie folgt auf die vier Unteraufgaben dieser Aufgabe:
      </p>

      <table class="table" style="min-width: 300px; width: 20%; margin-left: auto; margin-right: auto;">
      <thead>
        <tr>
          <th scope="col"></th>
          <th scope="col" class = "anzahl-head">Anzahl Fehler</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th scope="row">Teilaufgabe 1</th>
          <td class = "anzahl">{{ this.mySecurityResultsA2.totalErrorsLevel1 }}</td>
        </tr>
        <tr>
          <th scope="row">Teilaufgabe 2</th>
          <td class = "anzahl">{{ this.mySecurityResultsA2.totalErrorsLevel2 }}</td>
        </tr>
        <tr>
          <th scope="row">Teilaufgabe 3</th>
          <td class = "anzahl">{{ this.mySecurityResultsA2.totalErrorsLevel3 }}</td>
        </tr>
        <tr>
          <th scope="row">Teilaufgabe 4</th>
          <td class = "anzahl">{{ this.mySecurityResultsA2.totalErrorsLevel4 }}</td>
        </tr>
      </tbody>
    </table>
    </div>
    <p>Am Ende des Moduls haben Sie weitere Hinweise zu Pretty Good Privacy (PGP) und Kryptographie bekommen.</p>
    <div class="btn-container">
      <div class="custom-btn-secondary" @click="this.experimentBackClicked()">
        Zurück
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  emits: ["experiment-back"],
  data() {
    return {
      mySecurityResultsA1: {},
      mySecurityResultsA2: {},
      dataReady: false,
      securityA2ExampleImg: require("@/assets/inf/results/security_a2_example.png"),
      text: {
        letters: {
          'B': {
            encoded: 'P',
            decoded: 'B',
            replacement: '',
            isDecoded: false,
            hover: false
          },
          'C': {
            encoded: 'A',
            decoded: 'C',
            replacement: 'C',
            isDecoded: true
          },
          'D': {
            encoded: 'C',
            decoded: 'D',
            replacement: 'D',
            isDecoded: true
          },
          'E': {
            encoded: 'F',
            decoded: 'E',
            replacement: '',
            isDecoded: false,
            hover: false
          },
          'H': {
            encoded: 'B',
            decoded: 'H',
            replacement: 'H',
            isDecoded: true
          },
          'I': {
            encoded: 'Z',
            decoded: 'I',
            replacement: 'I',
            isDecoded: true
          },
          'K': {
            encoded: 'Q',
            decoded: 'K',
            replacement: 'K',
            isDecoded: true
          },
          'M': {
            encoded: 'R',
            decoded: 'M',
            replacement: 'M',
            isDecoded: true
          },
          'N': {
            encoded: 'W',
            decoded: 'N',
            replacement: 'N',
            isDecoded: true
          },
          'O': {
            encoded: 'U',
            decoded: 'O',
            replacement: 'O',
            isDecoded: true
          },
          'R': {
            encoded: 'S',
            decoded: 'R',
            replacement: 'R',
            isDecoded: true
          },
          'S': {
            encoded: 'I',
            decoded: 'S',
            replacement: '',
            isDecoded: false,
            hover: false
          },
          'T': {
            encoded: 'K',
            decoded: 'T',
            replacement: '',
            isDecoded: false,
            hover: false
          },
          'U': {
            encoded: 'O',
            decoded: 'U',
            replacement: '',
            isDecoded: false,
            hover: false
          },
          'V': {
            encoded: 'L',
            decoded: 'V',
            replacement: '',
            isDecoded: false,
            hover: false
          },
        },
        sentence:  ["DER", "BOSS", "VERSTECKT", "SICH", "UNTER", "SEINEM", "BETT"]
      }
    };
  },
  async mounted() {
    await this.getMyResultsSecurity();
    this.dataReady = true;
  },
  methods: {
    returnDate(date) {
      let day = new Date(date);
      const m = ["Januar", "Februar", "März", "April", "Mai", "Juni",
      "Juli", "August", "September", "Oktober", "November", "Dezember"];
      const str_op = day.getDate() + '. ' + m[day.getMonth()] + ' ' + day.getFullYear();
      return str_op;
    },
    returnTime(date) {
      let day = new Date(date).toTimeString();
      //const str_op = day.getHours() + ':' + day.getMinutes();
      return day.substring(0,5);
    },
    async getMyResultsSecurity() {
      try {
        const response = await fetch(
          process.env.VUE_APP_STRAPI_API_URL +
            "/api/inf-security-a1-log/myResults",
          {
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem("jwt")}`,
            },
          }
        );
        const res = await response.json();
        if (!res || res.length == 0) {
          this.mySecurityResultsA1 = "NO RESULTS FOUND";
        } else if (res.length > 1) {
          console.error("THERE ARE SEVERAL RESULTS FOR THIS USER");
          this.mySecurityResultsA1 = res;
        } else {
          this.mySecurityResultsA1 = res;
        }
      } catch (error) {
        console.log(error);
      }

      try {
        const response = await fetch(
          process.env.VUE_APP_STRAPI_API_URL +
            "/api/inf-security-a2-log/myResults",
          {
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem("jwt")}`,
            },
          }
        );
        const res = await response.json();
        if (!res || res.length == 0) {
          this.mySecurityResultsA2 = "NO RESULTS FOUND";
        } else if (res.length > 1) {
          console.error("THERE ARE SEVERAL RESULTS FOR THIS USER");
          this.mySecurityResultsA2 = res;
        } else {
          this.mySecurityResultsA2 = res;
        }
      } catch (error) {
        console.log(error);
      }
    },
    loadResultsIntoInputSecurity() {
      document.getElementById("security-assign1-results").value =
        JSON.stringify(this.mySortingResultsA1);
      document.getElementById("security-assign2-results").value =
        JSON.stringify(this.mySecurityResultsA2);
    },
    experimentBackClicked() {
      this.$emit("experiment-back");
    },
  },
};
</script>

<style scoped>
.additional-info-italic {
  font-style: italic;
}

.custom-btn,
.custom-btn-secondary {
  float: right;
}

p.sentence {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}

.word {
  display: flex;
  justify-content: center;
  margin: 10px;
}
.letter {
  display: flex;
  justify-content: center;
  font-size: 30px;
  min-width: 20px;
  padding: 3px;
  border: 2px solid transparent;
  font-family: monospace;
}

.letter.encoded {
  color: red;
}

.letter.decoded {
  color: green;
}

.letter.encoded.replaced, input.replaced {
  background-color: pink;
}

.anzahl {
  text-align: right;
}

.anzahl-head {
  text-align: center;
}
</style>
