<template>
  <div class="container" v-if="this.dataReady">
    <h1>Experimentiermodule</h1>
    <p>Im Rahmen der Experimentiermodule haben Sie erlebt, wie vielfältig die 
       Informatik ist. Sie haben dabei einen ersten Einblick in die Themenwelt der 
       Informatik und der im Studium vorkommenden Inhalte erhalten. Im 
       Experimentiermodul „Sortieren mit System“ haben Sie kennengelernt, wie mit 
       einem Computer sortiert wird und im Experimentiermodul „Mit Sicherheit“ wie 
      Verschlüsselung von digitaler Information funktioniert.
    </p>
    <p>
      In den nachfolgenden Auswertungen haben wir die Ergebnisse Ihrer Aufgabenbearbeitungen in den beiden Experimentiermodulen ausgewertet und wollen Ihnen die Möglichkeit geben, selbst über Ihre Vorgehensweise zu reflektieren.
    </p>

    <div class="experiment-modules-btn-container">
      <div :class="{ 'custom-btn': myOSA.sortingFinished, 'custom-btn-disabled': !myOSA.sortingFinished }" @click="this.sortingBtnClicked()">
        Ergebnisse von „Sortieren mit System”
      </div>
      <div :class="{ 'custom-btn': myOSA.securityFinished, 'custom-btn-disabled': !myOSA.securityFinished }" @click="this.securityBtnClicked()">
        Ergebnisse von „Mit Sicherheit”
      </div>
      <div :class="{ 'custom-btn': myOSA.variablesFinished, 'custom-btn-disabled': !myOSA.variablesFinished }" @click="this.variablesBtnClicked()">
        Ergebnisse von „Variablen”
      </div>
      <div :class="{ 'custom-btn': myOSA.databaseFinished, 'custom-btn-disabled': !myOSA.databaseFinished }" @click="this.databaseBtnClicked()">
        Ergebnisse von „Datenbanken”
      </div>
      <div :class="{ 'custom-btn': myOSA.graphFinished, 'custom-btn-disabled': !myOSA.graphFinished }" @click="this.graphBtnClicked()">
        Ergebnisse von „Graphen”
      </div>
    </div>

    <div class="btn-container">
      <div class="custom-btn-secondary" @click="this.backBtnClicked()">
        Zurück
      </div>
    </div>
  </div>
</template>

<script>
import * as myOSAhandler from "@/scripts/inf_my-osa-api-handler.js";

export default {
  components: {},
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  emits: ["experiments-overview-back", "show-security-results", "show-sorting-results", "show-variables-results","show-database-results", "show-graph-results"],
  data() {
    return {
      myOSA: {},
      dataReady: false,
    };
  },
  async mounted() {
    try {
      //this.user = JSON.parse(JSON.stringify(this.$store.getters.getUser));
      this.myOSA = await myOSAhandler.getMyInformatikOSA(this.user);
      this.dataReady = true;
    }
    catch(error) {
      this.logout = true;
    }
  },
  methods: {
    backBtnClicked() {
      this.$emit("experiments-overview-back");
    },
    sortingBtnClicked() {
      this.$emit("show-sorting-results");
    },
    securityBtnClicked() {
      this.$emit("show-security-results");
    },
    variablesBtnClicked() {
      this.$emit("show-variables-results");
    },
    databaseBtnClicked() {
      this.$emit("show-database-results");
    },
    graphBtnClicked() {
      this.$emit("show-graph-results");
    },
  },
};
</script>

<style scoped>
.additional-info-italic {
  font-style: italic;
}

.experiment-modules-btn-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  
  
}

.experiment-modules-btn-container > .custom-btn, .custom-btn-disabled {
  
  width: 290px;

  padding: 20px;
}

  
.custom-btn,
  .custom-btn-secondary {
    float: right;
  }
</style>
