<template>
  <div class="container">
    <div class="error-message" v-if="!this.user">
      Um auf dieses Modul zugreifen zu können, müssen Sie angemeldet sein.
      <router-link to="/"
        ><div class="custom-btn">Zur Anmeldung</div></router-link
      >
    </div>
    <div v-else>
      <div v-if="dataReady">
        <div class="introduction-container" v-if="showResultsIntroduction">
          <ResultsIntroduction
            :user="this.user"
            @show-fit-i-results="startFitIResults()"
            @show-experiment-results="startExperimentResultsLandingPage()"
            @close-results-clicked="closeResultsView()"
          ></ResultsIntroduction>
        </div>
        <div class="fit-i-container" v-if="showFitIResults">
          <FitIResults :user="this.user"
          @fiti-back="startResultsIntroduction()">
          </FitIResults>
        </div>
        <div
          class="experiments-results-landing-page-container"
          v-if="showExperimentsResultsLandingPage"
        >
          <ExperimentResultsLandingPage
            :user="this.user"
            @show-sorting-results="startSortingResults()"
            @show-security-results="startSecurityResults()"
            @show-variables-results="startVariableResults()"
            @show-database-results="startDatabaseResults()"
            @show-graph-results="startGraphResults()"
            @experiments-overview-back="startResultsIntroduction()"
          ></ExperimentResultsLandingPage>
        </div>
        <div class="sorting-results-container" v-if="showSortingResults">
          <SortingResults
          @experiment-back="startExperimentResultsLandingPage()"
          ></SortingResults>
        </div>
        <div class="security-results-container" v-if="showSecurityResults">
          <SecurityResults
          @experiment-back="startExperimentResultsLandingPage()"></SecurityResults>
        </div>
        <div class="variables-results-container" v-if="showVariablesResults">
          <VariablesResults
          @experiment-back="startExperimentResultsLandingPage()"></VariablesResults>
        </div>
        <div class="database-results-container" v-if="showDatabaseResults">
          <DatabaseResults
          @experiment-back="startExperimentResultsLandingPage()"></DatabaseResults>
        </div>
        <div class="graph-results-container" v-if="showGraphResults">
          <GraphResults
          @experiment-back="startExperimentResultsLandingPage()"></GraphResults>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ExperimentResultsLandingPage from "@/components/inf/results/ExperimentResultsLandingPage.vue";
import ResultsIntroduction from "@/components/inf/results/ResultsIntroduction.vue";
import SecurityResults from "@/components/inf/results/SecurityResults.vue";
import SortingResults from "@/components/inf/results/SortingResults.vue";
import VariablesResults from "@/components/inf/results/VariablesResults.vue";
import DatabaseResults from "@/components/inf/results/DatabaseResults.vue";
import GraphResults from "@/components/inf/results/GraphResults.vue";
import FitIResults from "@/components/inf/results/FitIResults.vue";

import * as scrollHelper from "@/helpers/scroll-helper.js";
import * as myOSAhandler from "@/scripts/inf_my-osa-api-handler.js";

export default {
  name: "ResultsView",
  components: {
    ResultsIntroduction,
    FitIResults,
    ExperimentResultsLandingPage,
    SecurityResults,
    SortingResults,
    VariablesResults,
    DatabaseResults,
    GraphResults,
  },
  emits: [],
  async mounted() {
    scrollHelper.scrollToTop();
    try {
      this.user = JSON.parse(JSON.stringify(this.$store.getters.getUser));
      this.myOSA = await myOSAhandler.getMyInformatikOSA(this.user);
      if(this.myOSA.sortingFinished){
        this.numFinished++
      }
      if(this.myOSA.securityFinished){
        this.numFinished++
      }
      if(this.myOSA.databaseFinished){
        this.numFinished++
      }
      if(this.myOSA.variablesFinished){
        this.numFinished++
      }
      if(this.myOSA.graphFinished){
        this.numFinished++
      }
      if(this.myOSA.fitIFinished
         && this.numFinished > 3
         && !this.myOSA.osaFinished){
            const uuid = this.uuidv4()
            this.myOSA.uuid = uuid
            this.myOSA.osaFinished = true
            await myOSAhandler.finishModule(this.myOSA.id, { osaFinished: true })
            await myOSAhandler.finishModule(this.myOSA.id, { uuid: uuid })
         }
      this.dataReady = true;
    }
    catch(error) {
      this.logout = true;
    }
  },
  unmounted(){},
  data() {
    return {
      dataReady: false,
      myOSA: {},
      resultsReady: false,
      showResultsIntroduction: true,
      showFitIResults: false,
      showExperimentsResultsLandingPage: false,
      showSortingResults: false,
      showSecurityResults: false,
      showVariablesResults: false,
      showDatabaseResults: false,
      showGraphResults: false,
      user: {},
      numFinished: 0
    };
  },
  methods: {
    uuidv4(){
      const hex = [...Array(256).keys()]
        .map(index => (index).toString(16).padStart(2, '0'));

      const r = crypto.getRandomValues(new Uint8Array(16));

      r[6] = (r[6] & 0x0f) | 0x40;
      r[8] = (r[8] & 0x3f) | 0x80;
      
      return [...r.entries()]
        .map(([index, int]) => [4, 6, 8, 10].includes(index) ? `-${hex[int]}` : hex[int])
        .join('');
    },
    hideAllComponents() {
      this.showResultsIntroduction = false;
      this.showFitIResults = false;
      this.showExperimentsResultsLandingPage = false;
      this.showSortingResults = false;
      this.showSecurityResults = false;
      this.showVariablesResults = false;
      this.showDatabaseResults = false;
      this.showGraphResults = false;
    },
    startResultsIntroduction() {
      this.hideAllComponents();
      this.showResultsIntroduction = true;
    },
    startFitIResults() {
      if(this.myOSA.fitIFinished){
        this.hideAllComponents();
        this.showFitIResults = true;
      }
      
    },
    startExperimentResultsLandingPage() {
      this.hideAllComponents();
      this.showExperimentsResultsLandingPage = true;
    },
    startSortingResults() {
      if(this.myOSA.sortingFinished){
      this.hideAllComponents();
      this.showSortingResults = true;
      }
    },
    startSecurityResults() {
      if(this.myOSA.securityFinished){
      this.hideAllComponents();
      this.showSecurityResults = true;
      }
    },
    startVariableResults() {
      if(this.myOSA.variablesFinished){
      this.hideAllComponents();
      this.showVariablesResults = true;
      }
    },
    startDatabaseResults() {
      if(this.myOSA.databaseFinished){
      this.hideAllComponents();
      this.showDatabaseResults = true;
      }
    },
    startGraphResults(){
      if(this.myOSA.graphFinished){
        this.hideAllComponents();
        this.showGraphResults = true;
      }
    },
    closeResultsView() {
      this.$router.push("/informatik");
    },
  },
};
</script>

<style scoped>
.custom-btn,
.custom-btn-secondary {
  float: right;
}
</style>
