<template>
  <div class="container" v-if="dataReady">
    <h1>Graphen</h1>

    <div class="row">
      <p>
        Im Experimentiermodul “Graphen” haben Sie erfahren, wie Graphen in der
        Informatik eingesetzt werden und welche Möglichkeiten sie bieten. Sie
        haben unterschiedliche Arten von Graphen kennen gelernt, und selbst
        Graphen aus vorhandenen Daten erstellt. Sie haben auch eine Methode
        angewendet um Zyklen in einem Graphen zu finden.
      </p>
      <p>
        Eine detaillierte Darstellung Ihrer Bearbeitung finden Sie in den
        Ergebnissen der beiden Aufgaben unten.
      </p>
    </div>

    <div class="row">
      <h2>Aufgabe 1: Einen Graph erstellen</h2>
      <p>
        Ihre Aufgabe im ersten Teil des Experiments war es, Knoten und Kanten
        aus einer Adjazenzmatrix auszulesen und diese in einen Graphen zu
        überführen. Hierfür mussten Sie die Knoten in den Zeilen- sowie
        Spaltenüberschriften erkennen und die Kanten zwischen den Knoten
        erkennen. Diese Kanten waren durch die entsprechenden Zahlen in der
        Matrix gekennzeichnet. Eine "1" in einer Zelle der Adjazenzmatrix
        bedeutet, dass eine Kante zwischen den Knoten aus der Zeile sowie dem
        Knoten der Spalte existiert. Ihre Ergebnisse sind:
      </p>

      <h3>1. Graph</h3>
      <div class="row animate glow delay-3">
        <div class="assignment-one-one">
          <p>Die Lösung zu Aufgabe 1.1 ist:</p>
          <div class="row image-container">
            <img :src="imageSolution11" id="graph-image-solution-11" alt="Lösung von Aufgabe 1.1" />
          </div>
        </div>
      </div>

      <div class="row">
        <h4>Ihre Fehler</h4>
        <div class="col-sm-12 col-md-4 col-xl-4">
          <table>
            <tr class="survey-row header">
              <th>Fehler</th>
              <th>Anzahl</th>
            </tr>

            <tr class="survey-row light">
              <td>Knotenname leer</td>
              <td>
                {{
                  myGraphResults.assign1Results.assign11Results
                    .nodeNameEmptyErrors
                }}
              </td>
            </tr>
            <tr class="survey-row light">
              <td>Knotenname falsch</td>
              <td>
                {{
                  myGraphResults.assign1Results.assign11Results
                    .nodeNameNotInCorrectNodesErrors
                }}
              </td>
            </tr>
            <tr class="survey-row light">
              <td>Knoten doppelt erstellt</td>
              <td>
                {{
                  myGraphResults.assign1Results.assign11Results
                    .nodeNameAlreadyInNodesErrors
                }}
              </td>
            </tr>
            <tr class="survey-row light">
              <td>Zu viele Knoten erstellt</td>
              <td>
                {{
                  myGraphResults.assign1Results.assign11Results
                    .tooManyNodesAddedErrors
                }}
              </td>
            </tr>
            <tr class="survey-row light">
              <td>Kante mit nur einen Knoten</td>
              <td>
                {{
                  myGraphResults.assign1Results.assign11Results
                    .sameNodeSelectedTwiceErrors
                }}
              </td>
            </tr>
            <tr class="survey-row light">
              <td>Kante doppelt erstellt</td>
              <td>
                {{
                  myGraphResults.assign1Results.assign11Results
                    .edgeAlreadyExistsErrors
                }}
              </td>
            </tr>
            <tr class="survey-row light">
              <td>Falsche Kante erstellt</td>
              <td>
                {{
                  myGraphResults.assign1Results.assign11Results
                    .wrongStartEndEdgeErrors
                }}
              </td>
            </tr>
          </table>
          <p>
            Um zur korrekten Lösung zu gelangen, haben Sie insgesamt          {{
            myGraphResults.assign1Results.assign11Results
              .submittedSolutionsCount
          }}
            {{
                myGraphResults.assign1Results.assign11Results.submittedSolutionsCount > 1 ? 'Abgaben' : 'Abgabe'
            }}
          überprüft.
          </p>
        </div>
        <div class="col-sm-12 col-md-4 col-xl-8">
          <div>
            Fehlerbeschreibung:
            <ul>
              <li>
                <strong>Knotenname leer:</strong> Dieser Fehler gibt an, wie oft
                Sie einen Knoten erstellt haben, ohne zuvor einen Knotennamen zu
                setzten. Die Knotennamen ergeben sich aus der Adjanzenzmatrix
                und sind nicht leer.
              </li>
              <li>
                <strong>Knotenname falsch:</strong> Dieser Fehler tritt auf,
                wenn sie einen Knoten mit einem Knotennamen hinzufügt haben,
                welcher nicht einem Knotennamen aus der Adjazenzmatrix entspricht.
                Das bedeutet, dass Sie einen Knoten mit einem falschen oder
                ungültigen Namen erstellt haben.
              </li>
              <li>
                <strong>Knoten doppelt erstellt:</strong> Dieser Fehler tritt
                auf, wenn Sie einen Knoten mit einem Namen erstellen wollten,
                welcher bereits zum Graphen hinzugefügt wurde. In dieser Aufgabe
                konnten keinen Knoten mit demselben Namen erstellen.
              </li>
              <li>
                <strong>Zu viele Knoten erstellt:</strong> Dieser Fehler tritt
                auf, wenn Sie versucht haben, mehr als 5 Knoten zu erstellen. In
                dieser Aufgabe konnten nur die Knoten <em>A, B, C, D</em> und
                <em>E</em> erstellt werden, welche den Zeilen- und
                Spaltenüberschriften der Adjazenzmatrix entsprechen.
              </li>
              <li>
                <strong>Kante mit nur einen Knoten:</strong> In dieser Aufgabe
                ist es nicht möglich Kanten mit nur einen Knoten zu erstellen.
                Dieser Fehler gibt an wie oft Sie eine Kante mit nur einen
                Knoten erstellen wollten.
              </li>
              <li>
                <strong>Kante doppelt erstellt:</strong> Zwischen zwei Knoten
                gibt es nur genau eine Kante. Dieser Fehler gibt an wie oft Sie
                eine Kante doppelt erstellen wollten.
              </li>
              <li>
                <strong>Falsche Kante erstellt:</strong> Sie haben eine Kante
                mit einen Start- und Zielenknoten erstellt, welche nicht
                den Knoten aus der Adjazenzmatrix entspricht. Kanten existieren nur zwischen Knoten,
                die eine "1" in der entsprechenden Zelle der Adjazenzmatrix besitzen.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <h3>2. Graph</h3>
    <div class="row animate glow delay-3">
      <div class="assignment-one-one">
        <p>Die Lösung zu Aufgabe 1.2 ist:</p>
        <div class="row image-container">
          <img :src="imageSolution12" id="graph-image-solution-12" alt="Lösung von Aufgabe 1.2" />
        </div>
      </div>
    </div>
    <h4>Ihre Fehler</h4>

    <div class="row">
      <div class="col-sm-12 col-md-4 col-xl-4">
        <table>
          <tr class="survey-row header">
            <th>Fehler</th>
            <th>Anzahl</th>
          </tr>

          <tr class="survey-row light">
            <td>Knotenname leer</td>
            <td>
              {{
                myGraphResults.assign1Results.assign12Results
                  .nodeNameEmptyErrors
              }}
            </td>
          </tr>
          <tr class="survey-row light">
            <td>Knotenname falsch</td>
            <td>
              {{
                myGraphResults.assign1Results.assign12Results
                  .nodeNameNotInCorrectNodesErrors
              }}
            </td>
          </tr>
          <tr class="survey-row light">
            <td>Knoten doppelt erstellt</td>
            <td>
              {{
                myGraphResults.assign1Results.assign12Results
                  .nodeNameAlreadyInNodesErrors
              }}
            </td>
          </tr>
          <tr class="survey-row light">
            <td>Zu viele Knoten erstellt</td>
            <td>
              {{
                myGraphResults.assign1Results.assign12Results
                  .tooManyNodesAddedErrors
              }}
            </td>
          </tr>
          <tr class="survey-row light">
            <td>Kante mit nur einen Knoten</td>
            <td>
              {{
                myGraphResults.assign1Results.assign12Results
                  .sameNodeSelectedTwiceErrors
              }}
            </td>
          </tr>
          <tr class="survey-row light">
            <td>Kante doppelt erstellt</td>
            <td>
              {{
                myGraphResults.assign1Results.assign12Results
                  .edgeAlreadyExistsErrors
              }}
            </td>
          </tr>
          <tr class="survey-row light">
            <td>Falsche Kante erstellt</td>
            <td>
              {{
                myGraphResults.assign1Results.assign12Results
                  .wrongStartEndEdgeErrors
              }}
            </td>
          </tr>
        </table>
        <p>
          Um zur korrekten Lösung zu gelangen, haben Sie insgesamt
          {{
            myGraphResults.assign1Results.assign12Results
              .submittedSolutionsCount
          }}
          {{ myGraphResults.assign1Results.assign12Results.submittedSolutionsCount > 1 ? 'Abgaben' : 'Abgabe' }} überprüft.
        </p>
      </div>
      <div class="col-sm-12 col-md-4 col-xl-8">
        <div>
          Fehlerbeschreibung:
          <ul>
            <li>
              <strong>Knotenname leer:</strong> Dieser Fehler gibt an, wie oft
              Sie einen Knoten erstellt haben, ohne zuvor einen Knotennamen zu
              setzten. Die Knotennamen ergeben sich aus der Adjanzenzmatrix
              und sind nicht leer.
            </li>
            <li>
              <strong>Knotenname falsch:</strong> Dieser Fehler tritt auf,
              wenn sie einen Knoten mit einem Knotennamen hinzufügt haben,
              welcher nicht einem Knotennamen aus der Adjazenzmatrix entspricht.
              Das bedeutet, dass Sie einen Knoten mit einem falschen oder
              ungültigen Namen erstellt haben.
            </li>
            <li>
              <strong>Knoten doppelt erstellt:</strong> Dieser Fehler tritt
              auf, wenn Sie einen Knoten mit einem Namen erstellen wollten,
              welcher bereits zum Graphen hinzugefügt wurde. In dieser Aufgabe
              konnten keinen Knoten mit demselben Namen erstellen.
            </li>
            <li>
              <strong>Zu viele Knoten erstellt:</strong> Dieser Fehler tritt
              auf, wenn Sie versucht haben, mehr als 6 Knoten zu erstellen. In
              dieser Aufgabe konnten nur die Knoten <em>A, B, C, D, E</em> und
              <em>F</em> erstellt werden, welche den Zeilen- und
              Spaltenüberschriften der Adjazenzmatrix entsprechen.
            </li>
            <li>
              <strong>Kante mit nur einen Knoten:</strong> In dieser Aufgabe
              ist es nicht möglich Kanten mit nur einen Knoten zu erstellen.
              Dieser Fehler gibt an wie oft Sie eine Kante mit nur einen
              Knoten erstellen wollten.
            </li>
            <li>
              <strong>Kante doppelt erstellt:</strong> Zwischen zwei Knoten
              gibt es nur genau eine Kante. Dieser Fehler gibt an wie oft Sie
              eine Kante doppelt erstellen wollten.
            </li>
            <li>
              <strong>Falsche Kante erstellt:</strong> Sie haben eine Kante
              mit einen Start- und Zielenknoten erstellt, welche nicht
              den Knoten aus der Adjazenzmatrix entspricht. Kanten existieren nur zwischen Knoten,
              die eine "1" in der entsprechenden Zelle der Adjazenzmatrix besitzen.
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="row">
      <h2>Aufgabe 2: Zyklen finden</h2>
      <p>In der zweiten Aufgabe dieses Moduls war ein Graph mit mehreren Zyklen gegeben. Ihre Aufgabe war es, alle im
        Graphen enthaltenen Zyklen zu finden und zu beschreiben. Dafür mussten Sie
        sowohl Länge als auch die, im Zyklus enthaltenen Knoten angeben.
      </p>

      <div class="assignment-two">
        <p>Die Lösung zu Aufgabe 2 ist:</p>
        <div class="row image-container">
          <img :src="imageSolution2" id="graph-image-solution-2" alt="Lösung von Aufgabe 2" />
        </div>
      </div>
      <div>
        <h4>Ihre Fehler</h4>
        <div class="row">
          <div class="col-sm-12 col-md-4 col-xl-4">
            <table>
              <tr class="survey-row header">
                <th>Fehler</th>
                <th>Anzahl</th>
              </tr>

              <tr class="survey-row light">
                <td>Zyklus mit falscher Länge erstellt</td>
                <td>{{ myGraphResults.assign2Results.totalWrongLengthErrors }}</td>
              </tr>
              <tr class="survey-row light">
                <td>Abgaben mit fehlenden Zyklen</td>
                <td>
                  {{ myGraphResults.assign2Results.totalMissingCyclesErrors }}
                </td>
              </tr>
              <tr class="survey-row light">
                <td>Zyklen mit fehlenden Knoten</td>
                <td>
                  {{ myGraphResults.assign2Results.totalMissingNodeNameErrors }}
                </td>
              </tr>
              <tr class="survey-row light">
                <td>Zyklen mit falschen Knoten</td>
                <td>{{ myGraphResults.assign2Results.totalWrongNodesErrors }}</td>
              </tr>
              <tr class="survey-row light">
                <td>Zu viele Zyklen erstellt</td>
                <td>
                  {{ myGraphResults.assign2Results.totalTooManyCyclesErrors }}
                </td>
              </tr>
            </table>
          </div>
          <div class="col-sm-12 col-md-4 col-xl-8">
            Fehlerbeschreibung:
            <ul>
              <li>
                <strong>Zyklus mit falscher Länge erstellt:</strong> Dieser
                Fehler gibt an, wie oft Sie versucht haben, einen Zyklus mit einer
                falschen Länge zu erstellen.
              </li>
              <li>
                <strong>Abgaben mit fehlenden Zyklen:</strong> Dieser Fehler
                tritt auf, wenn Sie versucht haben, Ihre Lösung mit mindestens einen fehlenden
                Zyklus abzugeben.
              </li>
              <li>
                <strong>Zyklen mit fehlenden Knoten:</strong> Dieser Fehler tritt
                auf, wenn Sie versucht haben, einen Zyklus abzugeben, welcher noch leere 
                Knoten enthält. Das bedeutet, dass dieser Zyklus nicht vollständig ist.
              </li>
              <li>
                <strong>Zyklen mit falschen Knoten:</strong> Jeder Zyklus der Lösung enthält eine 
                eindeutige Folge von Knoten. Wenn Sie versucht haben, einen Zyklus einen falschen Knoten, also 
                einen Knoten mehrmals oder einen, nicht im Graphen existierenden Knoten, hinzuzufügen, wird dies in 
                diesem Fehler festgehalten.
              </li>
              <li>
                <strong>Zu viele Zyklen erstellt:</strong> Der Graph aus Aufgabe 2 enthält genau 6 Zyklen. In diesem Fehler wird festgehalten, wie oft Sie 
                versucht haben mehr als 6 Zyklen zu erstellen.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="btn-container">
      <div class="custom-btn-secondary" @click="experimentBackClicked()">
        Zurück
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, defineEmits } from "vue";
const emit = defineEmits(["experiment-back"]);
const imageSolution11 = ref(
  require("@/assets/inf/results/graphsSolution11.png")
);
const imageSolution12 = ref(
  require("@/assets/inf/results/graphsSolution12.png")
);
const imageSolution2 = ref(require("@/assets/inf/results/graphsSolution2.png"));

const dataReady = ref(false);

onMounted(async () => {
  await getMyResultsGraph();
  dataReady.value = true;
});

const myGraphResults = ref({});

const getMyResultsGraph = async () => {
  try {
    const response = await fetch(
      process.env.VUE_APP_STRAPI_API_URL + "/api/inf-graph/myResults",
      {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("jwt")}`,
        },
      }
    );
    const res = await response.json();
    myGraphResults.value = res;
  } catch (error) {
    console.log(error);
  }
};

const experimentBackClicked = () => {
  emit("experiment-back");
};
</script>

<style scoped>
.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.custom-btn,
.custom-btn-secondary {
  float: right;
}

table {
  width: auto;
  border-collapse: collapse;
  table-layout: fixed;
  border-spacing: 0;
}

th,
td {
  border: 1px solid black;
  text-align: center;
  padding: 8px;
}

table th table {
  width: 100%;
}

table th table th,
table th table td {
  width: 200px;
  padding: 8px;
  box-sizing: border-box;
}

th {
  background-color: rgb(70, 110, 165);
  color: white;
}

td {
  background-color: rgb(173, 201, 230);
  color: black;
}

.survey-row.dark td {
  background-color: rgb(173, 201, 230);
}

.survey-row.light td {
  background-color: rgb(248, 248, 248);
}
</style>
