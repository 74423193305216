<template>
  <div class="container">
    <div v-if="!this.dataReady">
      <div class="d-flex justify-content-center">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
    <div v-if="dataReady">
      <div class="overview-container">
        <TheMyOSAComponent v-if="false"></TheMyOSAComponent>
        <h1>Meine Ergebnisse</h1>
        <p class="row">
          Im OSA Informatik erhielten Sie einen einführenden Überblick zum Fach
          und zum Studium an der TU Wien. Sie hatten Gelegenheit, verschiedene
          Bereiche eines Informatikstudiums im Rahmen von Experimentiermodulen
          kennen zu lernen und haben bei der Bearbeitung von Aufgaben die Denk-
          und Arbeitsweise der Informatik näher betrachtet. Mit Hilfe des
          Fragebogens FIT-I haben Sie Ihre persönlichen Voraussetzungen für das
          Informatikstudium und den Beruf ergründet. Welche Erkenntnisse lassen
          sich nun gewinnen?
        </p>

        <div class="osa-status-container" v-if="true">
          <p style="font-size: 1.2rem; margin-left: -0.8rem">Sie haben folgende Module abgeschlossen:</p>
          <div class="row">
            <div class="module"><div>
              <strong>Einführung in die Informatik: &nbsp;</strong> 
              {{
                myOSA.introFinished
                  ? " abgeschlossen"
                  : " noch nicht abgeschlossen"
              }} </div><div class="module-link" @click="this.$router.push('/informatik/introduction')">zum Modul&nbsp;<i class="bi bi-arrow-right-square-fill"></i></div>
            </div>
            
            <div class="module"><div>
              <strong>Fit für das Informatikstudium:&nbsp;</strong>
              {{
                myOSA.fitIFinished
                  ? "abgeschlossen"
                  : "noch nicht abgeschlossen"
              }}</div> <div class="module-link" @click="this.$router.push('/informatik/fiti')">zum Modul&nbsp;<i class="bi bi-arrow-right-square-fill"></i></div>
            </div>
            <div>
              <strong>Experimente:</strong>
              <ul>
                <li>
                  <div class="module"><div>
                    <strong>Variablen:&nbsp;</strong>
                    {{
                      myOSA.variablesFinished
                        ? "abgeschlossen"
                        : "noch nicht abgeschlossen"
                    }}</div><div class="module-link" @click="this.$router.push('/informatik/variables')">zum Modul&nbsp;<i class="bi bi-arrow-right-square-fill"></i></div>
                  </div>
                </li>
                <li>
                  <div class="module"><div>
                    <strong>Mit Sicherheit:&nbsp;</strong>
                    {{
                      myOSA.securityFinished
                        ? "abgeschlossen"
                        : "noch nicht abgeschlossen"
                    }}</div><div class="module-link" @click="this.$router.push('/informatik/security')">zum Modul&nbsp;<i class="bi bi-arrow-right-square-fill"></i></div>
                  </div>
                </li>

                <li>
                  <div class="module"><div>
                    <strong>Datenbanken:&nbsp;</strong>
                    {{
                      myOSA.databaseFinished
                        ? "abgeschlossen"
                        : "noch nicht abgeschlossen"
                    }}</div><div class="module-link" @click="this.$router.push('/informatik/database')">zum Modul&nbsp;<i class="bi bi-arrow-right-square-fill"></i></div>
                  </div>
                </li>

                <li>
                  <div class="module"><div>
                    <strong>Graphen:&nbsp;</strong>
                    {{
                      myOSA.graphFinished
                        ? "abgeschlossen"
                        : "noch nicht abgeschlossen"
                    }}</div><div class="module-link" @click="this.$router.push('/informatik/graphs')">zum Modul&nbsp;<i class="bi bi-arrow-right-square-fill"></i></div>
                  </div>
                </li>
                <li>
                  <div class="module"><div>
                    <strong>Sortieren mit System:&nbsp;</strong>
                    {{
                      myOSA.sortingFinished
                        ? "abgeschlossen"
                        : "noch nicht abgeschlossen"
                    }}</div>
                    <div class="module-link" @click="this.$router.push('/informatik/sorting')">zum Modul&nbsp;<i class="bi bi-arrow-right-square-fill"></i></div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="error-message" v-if="!myOSA.osaFinished">
          <p class="row">
            Sie haben noch nicht die benötigte Anzahl an Modulen abgeschlossen.
            Um Ihre Ergebnisse zu erhalten, schließen Sie den FIT-I Fragebogen
            und mindestens vier von fünf Experimenten aus dem OSA Informatik ab.
          </p>
        </div>
        <div v-else>
          <p class="row">
            Auf den folgenden Seiten erhalten Sie detailliertes Feedback zu
            FIT-I und zu den Experimenten im OSA Informatik. Zum einen können
            Sie sich Ihre Ergebnisse des FIT-I Fragebogens und zum anderen Ihre
            Ergebnisse der Experimentiermodule ansehen.
          </p>
          <p>
            <strong
              >Empfehlung: Um diese Ergebnisse dauerhaft festzuhalten, empfiehlt
              es sich, die Seiten zu drucken oder den Inhalt im PDF-Format zu
              speichern.</strong
            >
          </p>
          <div class="results-container">
            <div class="row">
              <div class="btn-container-results">
                <div
                  :class="{
                    'custom-btn': myOSA.fitIFinished,
                    'custom-btn-disabled': !myOSA.fitIFinished,
                  }"
                  @click="startFitIResults()"
                >
                  Mein FIT-I
                </div>
                <div class="custom-btn" @click="startExperimentResults()">
                  Meine Experimente
                </div>
              </div>
            </div>
          </div>
          <!-- <p class = "row">
            Sie können eine Gesamtdarstellung Ihrer Ergebnisse auch als PDF
            ansehen.
          </p>-->

          <div class="row" v-if="myOSA.osaFinished">
            <p>
              Mit dem <a :href="link">folgenden Link</a> können Sie die
              Voranmeldung für ein Bachelorstudium der Informatik oder
              Wirtschaftsinformatik aktivieren.
            </p>

            <div>
              Falls Probleme mit dem Link auftreten, können Sie Ihre persönliche
              Identifikationsnummer aus dem OSA auch kopieren:

              <div class="uuid-div">
                <input
                  id="input-uuid"
                  type="text"
                  v-model="myOSA.uuid"
                  disabled
                  @click="copyToClipboard()"
                />
                <!-- The button used to copy the text 
            <div class="custom-btn" @click="copyToClipboard()">ID kopieren</div>
            <div class="popup">
              <span class="popuptext" id="uuid-popup">ID wurde kopiert!</span>
            </div>-->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="btn-container btn-container-end">
        <div class="custom-btn-secondary" @click="closeResultsClicked()">
          Zurück zum OSA Informatik
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TheMyOSAComponent from "@/components/TheMyOSAComponent.vue";

import * as scrollHelper from "@/helpers/scroll-helper.js";
import * as myOSAhandler from "@/scripts/inf_my-osa-api-handler.js";

export default {
  name: "ResultsView",
  components: {
    TheMyOSAComponent,
  },
  emits: [
    "show-fit-i-results",
    "close-results-clicked",
    "show-experiment-results",
  ],
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dataReady: false,
      myOSA: {},
      link: "",
      osaFinished: false,
    };
  },
  async mounted() {
    scrollHelper.scrollToTop();
    this.myOSA = await myOSAhandler.getMyInformatikOSA(this.user.username); // await is necessary to wait for the results to be ready
    if (this.myOSA.osaFinished) {
      this.link =
        "https://tiss.tuwien.ac.at/osa_token/" + this.myOSA.uuid + "/2";
    }
    this.dataReady = true;
  },
  methods: {
    /**
     * Checks if all results are ready to be shown
     * @returns {boolean} true if all results are ready, false otherwise
     */
    startFitIResults() {
      this.$emit("show-fit-i-results");
    },
    startExperimentResults() {
      this.$emit("show-experiment-results");
    },
    closeResultsClicked() {
      this.$emit("close-results-clicked");
    },
    showResultsPDF() {
      alert("PDF Export noch nicht implementiert");
    },
    copyToClipboard() {
      // Get the text field
      var copyText = document.getElementById("input-uuid");

      // Select the text field
      copyText.select();

      // Set the selection range (for mobile devices)
      copyText.setSelectionRange(0, 99999);

      // Copy the text to the clipboard
      navigator.clipboard.writeText(copyText.value);

      // Alert the user that the text has been copied
      var popup = document.getElementById("uuid-popup");
      popup.classList.toggle("show");
    },
    openPopup() {},
  },
};
</script>

<style scoped>
.osa-status-container {
  margin: 2rem;
  border-top: 0.2rem solid #105490;
  border-bottom: 0.2rem solid #105490;
  padding: 0.5rem 2rem 0.5rem 2rem;
}
.module{
  display: flex;
  flex-direction: row;
  padding-right: 2rem;
  justify-content: space-between;
  border-bottom: 0.1rem solid lightblue;
}
.module-link {
  align-self: right;
  text-decoration: underline;
  cursor: pointer;
  margin-left: 1rem;
}

.module-link:hover {
  color: #105490;
  text-decoration: underline;
}

.results-container {
  margin-top: 40px;
  margin-bottom: 40px;
}
.btn-container-results {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.btn-container-results > .custom-btn,
.custom-btn-disabled {
  margin: 0 10px;
  width: 200px;
  margin: 0rem 2rem 2rem 2rem;
}

.btn-container-pdf {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.btn-container-end > .custom-btn-secondary,
.btn-container-end > .custom-btn {
  float: right;
}

#input-uuid {
  background-color: rgb(228, 243, 248);
  border-radius: 10px;
  margin: 10px;
  width: 50%;
  text-align: center;
}

/* Popup container */
.popup {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

/* The actual popup (appears on top) */
.popup .popuptext {
  visibility: hidden;
  width: 160px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 8px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -80px;
}

/* Popup arrow */
.popup .popuptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

/* Toggle this class when clicking on the popup container (hide and show the popup) */
.popup .show {
  visibility: visible;
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
}

/* Add animation (fade in the popup) */
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.uuid-div {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1em;
}
</style>
