<template>
  <div class="container" v-if="this.dataReady">

    <h1>Variablen</h1>

    <div class="row">
      <p>
        Das Experimentiermodul “Variablen” hat Ihnen gezeigt wie man Werte in Werte in 
        Variablen speichert. Dabei haben Sie die üblichen Bestandteile einer Variable 
        (Name, Typ, Wert) kennen gelernt, wie man Variablen deklariert und ihnen Werte 
        zuweist. 
      </p>
      <p>
        Eine detaillierte Darstellung Ihrer Bearbeitung finden Sie in den
        Ergebnissen der beiden Aufgaben unten.
      </p>
    </div>

    <div class="row">
      <h2>Aufgabe 1: Der Lebenslauf</h2>
      <p>
        Ihre Aufgabe im ersten Teil des Experiments war es, bestimmte persönliche
        Daten einer Person in Variablen zu speichern. Dabei sollten Sie auch
        die Variablen entsprechend sinnvoll benennen, ihnen den richtigen Typ 
        zuweisen als auch andere Beschränkungen beachten.
      </p>
      <p>Sie haben die Sprachnachricht von Beri in Form von Variablen erfasst.</p>

      <div class="row animate glow delay-3">
        <div class = "assignment-one">
            <div id = 'example'>
                <div id = 'image-box'>
                    <img :src= 'image' id = "beri-image" class = "animate glow delay-1"/>
                    <div class = 'bubble animate glow delay-2'>
                            <div class = "bubble-text">
                                <p>Hallo! Freut mich, Sie kennenzulernen!</p>
                                <p>Mein Name ist <span class = "essential vorname">Beri</span> <span class = "essential nachname">Taletovic</span>.</p> 
                                <p>Ich wurde am <span class = "essential plz">11.</span> <span class="essential month">Oktober</span> <span class="jahr">1997</span> geboren. </p>
                                <p>Zur Zeit wohne ich in <span class = "essential plz">1040</span> <span class = "essential wohnort"></span>Wien.</p>
                            </div>
                    </div>
                </div>
                <a href="https://www.flaticon.com/free-icons/teenager" title="teenager icons" class="credit-link" target="_blank">Teenager icons created by Freepik - Flaticon</a>
            </div>
        </div>
    </div>
<p></p>
<p></p>
<p>Das Ergebnis der Aufgabe ist:</p>
      <div class="variablelist">
        <div v-for = 'item in myVariablesResult.assignment1.final' :key = 'item.id'>
            <VariableBox
              :name = 'item.name'
              :type = 'item.type'
              :val = 'item.val' 
            />
        </div>
      </div>
      <p></p>

      <p>
        Dabei haben Sie die folgende Anzahl an Fehler der verschiedenen Arten gemacht:
      </p>

    <table>
  <tr class="survey-row header">
    <th>Leerer Name</th>
    <th>Name bereits verwendet</th>
    <th>Ungültiger Name</th>
    <th>Ungültiger Wert</th>
    <th>Falscher Typ</th>
    <th>Falscher Wert</th>
  </tr>
  
  <tr class="survey-row dark">
    <td>{{ myVariablesResult.assignment1.errors.empty }}</td>
    <td>{{ myVariablesResult.assignment1.errors.duplicate }}</td>
    <td>{{ myVariablesResult.assignment1.errors.invalid_name }}</td>
    <td>{{ myVariablesResult.assignment1.errors.invalid_value }}</td>
    <td>{{ myVariablesResult.assignment1.errors.wrong_type }}</td>
    <td>{{ myVariablesResult.assignment1.errors.wrong_value }}</td>
  </tr>
</table>
<p></p>
    </div>

<div class="row">
<h2>Aufgabe 2: Das vierte Glas</h2>
<p>Ihre Aufgabe im zweiten Teil des Experimentiermoduls war es, die Werte 
  den richtigen Variablen zuzuweisen, ohne einzelne Werte dabei zu verlieren.
  Sie hatten dabei eine leere Hilfsvariable zur Verfügung. </p>


  <p>Die Aufgabenstellung war, alle Gewürze den Gläsern mit dem richtigen Anfangsbuchstaben zuzuweisen, und dafür nur vier Zuweisungen zu 
     verwenden:</p>

     <div class="variablelist">
      <div>
        <VariableBox
              :name = '"P"'
              :type = '"Gewürz"'
              :val = '"Salz"' 
            />
      </div>
      <div>
        <VariableBox
              :name = '"S"'
              :type = '"Gewürz"'
              :val = '"Oregano"' 
            />
      </div>
      <div>
        <VariableBox
              :name = '"O"'
              :type = '"Gewürz"'
              :val = '"Pfeffer"' 
            />
      </div>
      <div>
        <VariableBox
              :name = '"Leer"'
              :type = '"Gewürz"'
              :val = '""' 
            />
      </div>
     </div>
<p></p>
     <p>Ein Ergebnis dieser Aufgabe ist:</p>
     <div class="variablelist">
      <div>
        <VariableBox
              :name = '"P"'
              :type = '"Gewürz"'
              :val = '"Pfeffer"' 
            />
      </div>
      <div>
        <VariableBox
              :name = '"S"'
              :type = '"Gewürz"'
              :val = '"Salz"' 
            />
      </div>
      <div>
        <VariableBox
              :name = '"O"'
              :type = '"Gewürz"'
              :val = '"Oregano"' 
            />
      </div>
      <div>
        <VariableBox
              :name = '"Leer"'
              :type = '"Gewürz"'
              :val = '"Pfeffer"' 
            />
      </div>
     </div>
     <p></p>

  <p>
  Sie haben insgesamt {{ myVariablesResult.assignment1.errors.errors }} mal eine Zuweisung gemacht, die zum Verlust eines Wertes
  geführt hätte.
</p>

<p>Die richtige Zuweisung aller Werte konnte mit nur 4 Zuweisungen erreicht werden. 
   Sie haben das richtige Ergebnis mit {{ myVariablesResult.assignment2.swaps }} Zuweisungen erreicht.
</p>
</div>


    <div class="btn-container">
      <div class="custom-btn-secondary" @click="this.experimentBackClicked()">
        Zurück
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import VariableBox from '@/components/inf/results/helpers/VariableBox.vue'

export default {
  components: {VariableBox},
  setup() {
    const image = ref(require("@/assets/inf/variable/beri-image.png")) // Make sure this path is correct

    return {
      // other data properties
      image,
      // rest of your setup function
    }
  },
  props: {
    user: {
      type: Object,
      required: true,
      myVariablesResult: {}
    },
  },
  emits: ["experiment-back"],
  data() {
    return {
      dataReady: false,
    };
  },
  async mounted() {
    await this.getMyResultsVariables();
    this.dataReady = true;
  },
  methods: {
    async getMyResultsVariables() {
      try {
        const response = await fetch(
          process.env.VUE_APP_STRAPI_API_URL +
            "/api/inf-variable/myResults",
          {
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem("jwt")}`,
            },
          }
        );
        const res = await response.json();
        this.myVariablesResult = res[0];
      } catch (error) {
        console.log(error);
      }
    },
    experimentBackClicked() {
      this.$emit("experiment-back");
    },
  },
};
</script>

<style scoped>
#image-box {
  display: flex; justify-content: center; align-items: center;
}
#beri-image {
  min-width:100px; width: 20%; height: auto; filter: drop-shadow(5px 5px 2px #A3A3A3);
}

.bubble {
    position: relative;
    background: #ffffff;
    color: #000000;
    font-family: Arial;
    font-size: 40px;
    filter: drop-shadow(5px 5px 2px #A3A3A3);
    text-align: center;
    width: 300px;
    height: 220px;
    border-radius: 10px;
    padding: 0px;
    border: #000000 solid 3px;
}
.bubble:after {
    content: '';
    position: absolute;
    display: block;
    width: 0;
    z-index: 1;
    border-style: solid;
    border-color: transparent #ffffff;
    border-width: 20px 20px 20px 0;
    top: 50%;
    left: -20px;
    margin-top: -20px;
}
.bubble:before {
    content: '';
    position: absolute;
    width: 0;
    z-index: 0;
    border-style: solid;
    border-color: transparent #000000;
    border-width: 23px 23px 23px 0;
    top: 50%;
    left: -24.5px;
    margin-top: -23px;
    display: block;
}
.bubble-text {font-size: 20px;}
.credit-link {
  font-size: 10px; /* or even smaller if you want */
  color: #555; /* Optional: change the color if you like */
  text-align: center; /* Optional: centers the link */
  display: block; /* Optional: places the link on its own line */
  margin-top: 10px; /* Optional: adds space above the link */
}

.custom-btn,
.custom-btn-secondary {
  float: right;
}
table {
  width: auto;
  border-collapse: collapse;
  table-layout: fixed; 
  border-spacing: 0;
}


th, td {
  border: 1px solid black;
  text-align: center;
  padding: 8px;
}
table th table {
  width: 100%; 
}
table th table th, 
table th table td {
  width: 200px;
  padding: 8px; 
  box-sizing: border-box; 
}


th {
  background-color: rgb(70, 110, 165);
  color: white;
}

td {
  background-color: rgb(173, 201, 230);
  color: black;
}

.variablelist {
  display: flex;
}
.survey-row.dark td {
  background-color: rgb(173, 201, 230);
}

.survey-row.light td {
  background-color: rgb(248, 248, 248);
}
</style>