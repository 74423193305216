<template>
  <div class="main-tracker col-md-6 col-sm-2 my-5 px-2 py-2">
    <div id="title-container">
      <div
        id="osa-title"
        @click="this.$router.push(osaPath)"
        class="hover-underline-animation mx-2"
      >
        {{ osaName }}
      </div>
    </div>
    <div id="body-container">
      <div
        class="osa-image col-md-5 mx-2 my-2"
        @click="this.$router.push(osaPath)"
        @mouseenter="toggleTitleAnimation()"
        @mouseleave="toggleTitleAnimation()"
      >
        <img
          :src="require(`@/assets/res/${imageFilename}`)"
          style="width: 100%; border-radius: 5px"
        />
      </div>
      <div class="modules-container mx-2">
        <div v-for="(module, index) in modules" :key="index">
          <div
            class="module-container hover-underline-animation my-1"
            @click="this.$router.push(module.modulePath)"
          >
            <div class="module-title">
              {{ module.moduleTitle }}
            </div>
            <div
              class="module-finished-check"
              :class="{ green: module.moduleFinished }"
            >
              <i class="bi bi-check"></i>
            </div>
            <!--<div class="module-delete" @click="deleteModuleProgress()" style="cursor:pointer"><i class="bi bi-trash3"></i></div>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseOSAModuleTracker.vue",
  props: {
    osaName: { type: String, required: true }, //name of osa/study program, e.g. 'Informatik'
    osaPath: { type: String, required: true },
    imageFilename: { type: String, required: true },
    modules: { type: Array, required: true }, //array with modules of osa/study program
    /* contains:  {
            moduleTitle: String, //name of the module/assignment of OSA
            modulePath: String, //path to module, e.g. /informatik/sortieren-mit-system.html
            moduleFinished: Boolean, //show green check if moduleFinished = true
            moduleRESTInterface: String, //url to rest interface, to delete module progress
        }*/
  },
  emits: [],
  data() {
    return {};
  },
  methods: {
    deleteModuleProgress() {
      alert("TODO: NOT IMPLEMENTED");
    },
    toggleTitleAnimation() {
      let el = document.getElementById("osa-title");
      el.classList.toggle("underline-animation-hover");
    },
  },
};
</script>

<style lang="scss" scoped>
.main-tracker {
  border: 2px solid black;
  border-radius: 5px;
}

#body-container {
  display: flex;
  flex-direction: row;
}

.osa-image {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.modules-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  font-size: 1em;
  line-height: 1.8em;
}

.module-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid black;
}

#osa-title {
  font-size: 22px;
  color: #006699;
  font-family: "PT Sans", sans-serif;
  line-height: 44px;
  cursor: pointer;
  font-weight: bold;
}

.module-container:hover {
  cursor: pointer;
}

.module-finished-check {
  font-size: 2em;
  color: #afafaf;
  display: flex;
  justify-content: center;
  align-items: center;
}
.green {
  color: green;
}
.underline-animation {
  display: inline-block;
  position: relative;
}
.underline-animation::after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #006699;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}
.underline-animation-hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}
</style>
