<template>
  <div class="my-4" v-if="dataReady">
    <div class="accordion">
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingOne">
          <button
            class="accordion-button"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseOne"
            aria-expanded="true"
            aria-controls="collapseOne"
          >
          <div class="header-text hover-underline-animation">Mein OSA im Überblick</div>

          </button>
        </h2>
        <div
          id="collapseOne"
          class="accordion-collapse collapse show"
          aria-labelledby="headingOne"
          data-bs-parent="#accordionExample"
        >
          <div class="accordion-body">
            <div v-if="this.myOSAs.length == 0">
              Sie haben bisher noch kein OSA gestartet.
              <!--<div class="custom-btn" @click="this.$emit('osa-overview-clicked')">Zum OSA Angebot</div>-->
            </div>
            <div class="osa-module-tracker-container" v-else>
              <div v-for="osa in myOSAs" :key="osa.osaName">
                <BaseOSAModuleTracker
                  :osaName="osa.osaName"
                  :osaPath="osa.osaPath"
                  :imageFilename="osa.imageFilename"
                  :modules="osa.modules"
                >
                </BaseOSAModuleTracker>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import BaseOSAModuleTracker from "@/components/BaseOSAModuleTracker.vue";
import * as osaHandler from "@/scripts/inf_my-osa-api-handler.js";

export default {
  name: "TheMyOSAComponent.vue",
  components: {
    BaseOSAModuleTracker,
  },
  emits: ["osa-overview-clicked"],
  async mounted() {
    //add Informatik OSA
    let myInformatikOSATracker = await this.getMyInformatikOSATracker();
    if (!(Object.entries(myInformatikOSATracker).length === 0))
      this.myOSAs.push(myInformatikOSATracker);

    this.dataReady = true;
  },
  data() {
    return {
      dataReady: false,
      myOSAs: [], //array containing all osas
      /*          osa-entry 
osaName: "Test Name 1",
            modules: [{
                moduleTitle: "Test Module 1",
                modulePath: "test path 1",
                moduleFinished: true,
                moduleRESTInterface: "test interface",
              },{
                moduleTitle: "Test Module 2",
                modulePath: "test path 2",
                moduleFinished: true,
                moduleRESTInterface: "test interface",
              },{
                moduleTitle: "Test Module 3",
                modulePath: "test path 3",
                moduleFinished: false,
                moduleRESTInterface: "test interface", //necessary to delete progress
              }], */
    };
  },
  methods: {
    async getMyInformatikOSATracker() {
      let myInformatikOSA = await osaHandler.getMyInformatikOSA(
        this.$store.getters.getUser.username
      );

      if (!(Object.entries(myInformatikOSA).length === 0)) {
        //create informtik-osa entry for myOSAs
        let osa = {
          osaName: { type: String },
          osaPath: { type: String },
          imageFilename: { type: String },
          modules: [],
        };

        osa.osaName = "Informatik"; //set title
        osa.osaPath = "/informatik"; //set path
        osa.imageFilename = "card_img_informatik.png"; //set filename
        let sortingModule = {
          moduleTitle: "Sortieren mit System!",
          modulePath: "/informatik/sorting",
          moduleFinished: myInformatikOSA.sortingFinished,
        };
        let introModule = {
          moduleTitle: "Einführung in die Informatik",
          modulePath: "/informatik/introduction",
          moduleFinished: myInformatikOSA.introFinished,
        };
        let securityModule = {
          moduleTitle: "Mit Sicherheit",
          modulePath: "/informatik/security",
          moduleFinished: myInformatikOSA.securityFinished,
        };
        let variablesModule = {
          moduleTitle: "Variablen",
          modulePath: "/informatik/variables",
          moduleFinished: myInformatikOSA.variablesFinished,
        };
        let databaseModule = {
          moduleTitle: "Datenbanken",
          modulePath: "/informatik/database",
          moduleFinished: myInformatikOSA.databaseFinished,
        };
        let graphsModule = {
          moduleTitle: "Graphen",
          modulePath: "/informatik/graphs",
          moduleFinished: myInformatikOSA.graphFinished,
        };
        let fitIModule = {
          moduleTitle: "Fit für das Informatikstudium?! (FIT-I)",
          modulePath: "/informatik/fiti",
          moduleFinished: myInformatikOSA.fitIFinished,
        };
        osa.modules.push(introModule);
        osa.modules.push(variablesModule);
        osa.modules.push(securityModule);
        osa.modules.push(databaseModule);
        osa.modules.push(graphsModule);
        osa.modules.push(sortingModule);
        osa.modules.push(fitIModule);

        return osa;
      }
      return {};
    },
  },
};
</script>

<style lang="scss" scoped>

.header-text {
  width: 100%;
  display: flex;
  font-size: 32px;
  color: #006699;
  font-family: "PT Sans", sans-serif;
  font-weight: 700;
}

.accordion-button{  
  border: 2px solid black;
  font-size: 2em;
  background-color: white;
}
.accordion-button:hover{  box-shadow:0 0 0px 1px black;
}

.accordion-item {
  border: none;
}
</style>
