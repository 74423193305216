<template>
  <div class="container" v-if="this.dataReady">
    <div
      class="test-area"
      style="
        background-color: aliceblue;
        border: 1px solid gray;
        padding: 10px;
        margin-bottom: 20px;
        display: none;
      "
    >
      <strong>Sortieren mit System</strong>
      <button class="" @click="loadResultsIntoInputSorting()">
        Ergebnisse abrufen
      </button>
      <div>Assignment 1</div>
      <input
        id="sorting-assign1-results"
        type="text"
        class="form-control"
        aria-label="Sizing example input"
        aria-describedby="inputGroup-sizing-sm"
        readonly
        style="background-color: #e0e0e0"
      />
      <div>Assignment 2</div>
      <input
        id="sorting-assign2-results"
        type="text"
        class="form-control"
        aria-label="Sizing example input"
        aria-describedby="inputGroup-sizing-sm"
        readonly
        style="background-color: #e0e0e0"
      />
    </div>
    <h1>Sortieren mit System</h1>
    <p>
      Der Inhalt des Experimentiermoduls „Sortieren mit System“ beschäftigt sich
      mit den Herausforderungen, die für uns Menschen vermeintlich „einfache“
      Aufgaben, wie das Sortieren von Karten für Computer bedeuten. Sie haben
      kennengelernt, dass Computer immer einer definierten Abfolge von
      Schritten folgen, die zuvor festgelegt werden muss. Auch wenn das für
      Menschen umständlich erscheint, ist es wichtig, dass diese Schritte in
      einer für Computer verständlichen Sprache formuliert werden. Dadurch
      lassen sich auch sehr viel umfangreichere Problemstellungen lösen, die für
      uns Menschen nicht einfach zu bewältigen wären.
    </p>
    <p>
      Sie haben die Bearbeitung des Experiments „Sortieren mit System“ am
      {{ this.returnDate(this.mySortingResultsA2.endTime) }} um {{ this.returnTime(this.mySortingResultsA2.endTime) }} abgeschlossen.
    </p>

    <h3>Aufgabe 1</h3>
    <p>
      Ihre Aufgabe im ersten Teil des Experiments war es eine Folge von Karten
      aufsteigend zu sortieren. Dafür sollten Sie nach einem bestimmten
      Algorithmus vorgehen, der in der Informatik oft angewendet wird, um solche
      Aufgabenstellungen für Computer verständlich macht. Generell gibt es dafür
      eine Vielzahl von möglichen Vorgehensweisen, doch der von Ihnen erstellte
      Sortieralgorithmus heißt „Selection Sort“.
    </p>
    <div style="display: flex; justify-content: center">
      <img
        style="width: 70%; height: auto; max-width: 80%"
        :src="sortingA1ExampleImg"
        alt="Sortieren mit System: Beispiel für die Aufgabe 1"
      />
    </div>
    <p>Sie konnten dabei nach diesen vier Mustern vorgehen:</p>
    <ul>
      <li>Links lesen, links fixieren</li>
      <li>Links lesen, rechts fixieren</li>
      <li>Rechts lesen, links fixieren</li>
      <li>Rechts lesen, rechts fixieren</li>
    </ul>

    <p>
      Ihren Lösungsweg haben Sie durch die Verwendung der Werkzeuge (Lesen,
      Merken, Tauschen, Fixieren) in den ersten Schritten beim Erstellen des
      Algorithmus gewählt. Die Leserichtung wird festgelegt, indem Sie die zuerst
      die ganz linke Karte oder die ganz rechte Karte lesen. Das Festlegen einer
      Fixierrichtung funktioniert genauso. Die erste korrekt fixierte Karte legt
      diese fest.
    </p>
    <p>
      In Ihrem Algorithmus sind Sie nach dem
      <strong>{{ this.getSortingA1SolutionPath() }} Lösungsweg</strong>
      vorgegangen. Dabei haben Sie
      <strong>{{ this.getSortingA1TotalWrong().total }} Fehler</strong> gemacht
      und insgesamt
      <strong
        >{{
          this.getSortingA1TotalCorrect().total +
          this.getSortingA1TotalWrong().total
        }}
        Schritte</strong
      >
      zur korrekten Lösung benötigt.
    </p>
    <p>
      Die minimale Anzahl von Schritten in den unterschiedlichen Lösungswegen
      beträgt:
    </p>
    <ul>
      <li>Links lesen, links fixieren: 33</li>
      <li>Links lesen, rechts fixieren: 28</li>
      <li>Rechts lesen, links fixieren: 29</li>
      <li>Rechts lesen, rechts fixieren: 33</li>
    </ul>

    <p class="additional-info-italic">
      Ein kurzer Exkurs in die Welt der Sortieralgorithmen: Die Anzahl der
      Schritte, die ein Sortieralgorithmus zum Lösen einer Aufgabe benötigt, ist
      immer auch von der Zusammensetzung der zu sortierenden Elemente abhängig.
      Das bedeutet, dass stark durchmischte Karten meistens mehr Schritte
      brauchen, um zu einer korrekten Lösung zu kommen.
    </p>

    <p  v-if="false">
      In der folgenden Tabelle sehen Sie, wie Sie die Werkzeuge in der Aufgabe
      eingesetzt haben. Dazu werden Ihre Aktionen zur Lösung in vier zeitliche
      Abschnitte unterteilt. <span>Sie können für jedes Wegzeug vergleichen wie oft
      Sie es falsch beziehungsweise richtig in den jeweiligen Abschnitten
      eingesetzt haben. Für jedes Werkzeug ist in der Spalte “Soll” angegeben,
      wie oft das Werkzeug im jeweiligen Abschnitt in einer Optimallösung
      eingesetzt werden musste. Die Anzahl, der von Ihnen durchgeführten
      Aktionen pro Werkzeug und Abschnitt werden in der Spalte “Ist” angegeben.
      Grüne Zellen zeigen Ihnen, dass Sie das Werkzeug immer korrekt verwendet
      haben.</span> Eine rote Zelle verdeutlicht, wie weit Sie von der Optimallösung
      für das Werkzeug im jeweiligen Abschnitt entfernt waren.
    </p>
    <table class="table" v-if = "false">
      <thead>
        <tr>
          <th scope="col">Abschnitt</th>
          <th scope="col">Lesen</th>
          <th scope="col">Merken</th>
          <th scope="col">Fixieren</th>
          <th scope="col">Tauschen</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th scope="row">1</th>
          <td>
            {{ mySortingResultsA1.firstQuarter.correctActions.correctRead }}
          </td>
          <td>
            {{ mySortingResultsA1.firstQuarter.correctActions.correctSave }}
          </td>
          <td>
            {{ mySortingResultsA1.firstQuarter.correctActions.correctFix }}
          </td>
          <td>
            {{ mySortingResultsA1.firstQuarter.correctActions.correctSwap }}
          </td>
        </tr>
        <tr>
          <th scope="row">2</th>
          <td>
            {{ mySortingResultsA1.secondQuarter.correctActions.correctRead }}
          </td>
          <td>
            {{ mySortingResultsA1.secondQuarter.correctActions.correctSave }}
          </td>
          <td>
            {{ mySortingResultsA1.secondQuarter.correctActions.correctFix }}
          </td>
          <td>
            {{ mySortingResultsA1.secondQuarter.correctActions.correctSwap }}
          </td>
        </tr>
        <tr>
          <th scope="row">3</th>
          <td>
            {{ mySortingResultsA1.thirdQuarter.correctActions.correctRead }}
          </td>
          <td>
            {{ mySortingResultsA1.thirdQuarter.correctActions.correctSave }}
          </td>
          <td>
            {{ mySortingResultsA1.thirdQuarter.correctActions.correctFix }}
          </td>
          <td>
            {{ mySortingResultsA1.thirdQuarter.correctActions.correctSwap }}
          </td>
        </tr>
        <tr>
          <th scope="row">4</th>
          <td>
            {{ mySortingResultsA1.fourthQuarter.correctActions.correctRead }}
          </td>
          <td>
            {{ mySortingResultsA1.fourthQuarter.correctActions.correctSave }}
          </td>
          <td>
            {{ mySortingResultsA1.fourthQuarter.correctActions.correctFix }}
          </td>
          <td>
            {{ mySortingResultsA1.fourthQuarter.correctActions.correctSwap }}
          </td>
        </tr>
        <tr>
          <th scope="row">Gesamt</th>
          <td>{{ getSortingA1TotalCorrect().read }}</td>
          <td>{{ getSortingA1TotalCorrect().save }}</td>
          <td>{{ getSortingA1TotalCorrect().fix }}</td>
          <td>{{ getSortingA1TotalCorrect().swap }}</td>
        </tr>
      </tbody>
    </table>
    <p>
      In der folgenden Tabelle sehen Sie wie oft Sie die verschiedenen Werkzeuge
      pro Abschnitt falsch eingesetzt haben. Dazu werden Ihre Aktionen zur Lösung in vier zeitliche
      Abschnitte unterteilt.
    </p>
    <table class="table">
      <thead>
        <tr>
          <th scope = "col"> </th>
          <th colspan = "4" style = "text-align: center;">Anzahl Fehler</th>
        </tr>
        <tr>
          <th scope="col">Abschnitt</th>
          <th scope="col">Lesen</th>
          <th scope="col">Merken</th>
          <th scope="col">Fixieren</th>
          <th scope="col">Tauschen</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th scope="row">1. Viertel</th>
          <td :style = "{backgroundColor: getBackgroundColor(mySortingResultsA1.firstQuarter.wrongActions.wrongRead)}">{{mySortingResultsA1.firstQuarter.wrongActions.wrongRead }}</td>
          <td :style = "{backgroundColor: getBackgroundColor(mySortingResultsA1.firstQuarter.wrongActions.wrongSave)}">{{ mySortingResultsA1.firstQuarter.wrongActions.wrongSave }}</td>
          <td :style = "{backgroundColor: getBackgroundColor(mySortingResultsA1.firstQuarter.wrongActions.wrongFix)}">{{ mySortingResultsA1.firstQuarter.wrongActions.wrongFix }}</td>
          <td :style = "{backgroundColor: getBackgroundColor(mySortingResultsA1.firstQuarter.wrongActions.wrongSwap)}">{{ mySortingResultsA1.firstQuarter.wrongActions.wrongSwap }}</td>
        </tr>
        <tr>
          <th scope="row">2. Viertel</th>
          <td :style = "{backgroundColor: getBackgroundColor(mySortingResultsA1.secondQuarter.wrongActions.wrongRead)}">{{ mySortingResultsA1.secondQuarter.wrongActions.wrongRead }}</td>
          <td :style = "{backgroundColor: getBackgroundColor(mySortingResultsA1.secondQuarter.wrongActions.wrongSave)}">{{ mySortingResultsA1.secondQuarter.wrongActions.wrongSave }}</td>
          <td :style = "{backgroundColor: getBackgroundColor(mySortingResultsA1.secondQuarter.wrongActions.wrongFix)}">{{ mySortingResultsA1.secondQuarter.wrongActions.wrongFix }}</td>
          <td :style = "{backgroundColor: getBackgroundColor(mySortingResultsA1.secondQuarter.wrongActions.wrongSwap)}">{{ mySortingResultsA1.secondQuarter.wrongActions.wrongSwap }}</td>
        </tr>
        <tr>
          <th scope="row">3. Viertel</th>
          <td :style = "{backgroundColor: getBackgroundColor(mySortingResultsA1.thirdQuarter.wrongActions.wrongRead)}">{{ mySortingResultsA1.thirdQuarter.wrongActions.wrongRead }}</td>
          <td :style = "{backgroundColor: getBackgroundColor(mySortingResultsA1.thirdQuarter.wrongActions.wrongSave)}">{{ mySortingResultsA1.thirdQuarter.wrongActions.wrongSave }}</td>
          <td :style = "{backgroundColor: getBackgroundColor(mySortingResultsA1.thirdQuarter.wrongActions.wrongFix)}">{{ mySortingResultsA1.thirdQuarter.wrongActions.wrongFix }}</td>
          <td :style = "{backgroundColor: getBackgroundColor(mySortingResultsA1.thirdQuarter.wrongActions.wrongSwap)}">{{ mySortingResultsA1.thirdQuarter.wrongActions.wrongSwap }}</td>
        </tr>
        <tr>
          <th scope="row">4. Viertel</th>
          <td :style = "{backgroundColor: getBackgroundColor(mySortingResultsA1.fourthQuarter.wrongActions.wrongRead)}">{{ mySortingResultsA1.fourthQuarter.wrongActions.wrongRead }}</td>
          <td :style = "{backgroundColor: getBackgroundColor(mySortingResultsA1.fourthQuarter.wrongActions.wrongSave)}">{{ mySortingResultsA1.fourthQuarter.wrongActions.wrongSave }}</td>
          <td :style = "{backgroundColor: getBackgroundColor(mySortingResultsA1.fourthQuarter.wrongActions.wrongFix)}">{{ mySortingResultsA1.fourthQuarter.wrongActions.wrongFix }}</td>
          <td :style = "{backgroundColor: getBackgroundColor(mySortingResultsA1.fourthQuarter.wrongActions.wrongSwap)}">{{ mySortingResultsA1.fourthQuarter.wrongActions.wrongSwap }}</td>
        </tr>
        <tr>
          <th scope="row">Gesamt</th>
          <td><strong>{{ getSortingA1TotalWrong().read }}</strong></td>
          <td><strong>{{ getSortingA1TotalWrong().save }}</strong></td>
          <td><strong>{{ getSortingA1TotalWrong().fix }}</strong></td>
          <td><strong>{{ getSortingA1TotalWrong().swap }}</strong></td>
        </tr>
      </tbody>
    </table>
    <h3>Aufgabe 2</h3>
    <p>
      Ihre Aufgabe im zweiten Teil des Experiments war es, eine Folge von Karten
      aufsteigend zu sortieren. Dafür sollten Sie nach einem bestimmten
      Algorithmus vorgehen, der in der Informatik oft angewendet wird, um solche
      Aufgabenstellungen für Computer verständlich macht. Generell gibt es dafür
      eine Vielzahl von möglichen Vorgehensweisen, doch der von Ihnen erstellte
      Sortieralgorithmus heißt „Selection Sort“.
    </p>
    <div style="display: flex; justify-content: center">
      <img
        style="width: 80%; height: auto; max-width: 80%"
        :src="sortingA2ExampleImg"
        alt="Sortieren mit System: Beispiel für die Aufgabe 1"
      />
    </div>
    <p>
      Sie haben diese Aufgabe mit insgesamt 
      <strong>{{ this.getSortingA2TotalErrors()}} Fehlern</strong>
       abgeschlossen. Unter
      anderem haben Sie {{ this.mySortingResultsA2.usedReadWithoutSettingReadErrors }} mal das Lesewerkzeug und {{ this.mySortingResultsA2.usedSaveWithoutSettingSaveErrors }} mal das Merkwerkzeug
      verwendet, ohne dieses zuvor zu initialisieren. Daneben haben Sie noch {{ this.mySortingResultsA2.noCardSavedBeforeSwapErrors }}
      mal versucht, Karten zu tauschen, obwohl Sie zuvor keine Karte für einen
      Tausch „gemerkt“ haben und {{ this.mySortingResultsA2.fixedAllCardsErrors }} mal haben Sie versucht, Karten zu fixieren,
      obwohl bereits alle fixiert waren. Zusätzlich haben Sie {{ this.mySortingResultsA2.readOutOfBoundsErrors }} mal versucht,
      auf eine Karte zu lesen oder zu merken, die nicht existierte (d.h. auf
      die Karte an Stelle 0 oder an Stelle 6). Sie haben auch {{ this.mySortingResultsA2.emptySlotErrors }} mal versucht,
      einen unvollständigen Algorithmus auszuführen, und diesen {{ this.mySortingResultsA2.finishedWithWrongOrderErrors }} mal mit einer
      falsch sortieren Lösung abgeschlossen.
    </p>
    <p>
      Für die Bearbeitung dieses Beispiels haben Sie {{ this.mySortingResultsA2.neededSeconds }} Sekunden benötigt,
      {{ this.mySortingResultsA2.dropActionSlot }} Algorithmusteile verschoben und {{ this.mySortingResultsA2.changedDropdowns }} Auswahlfelder verändert.
    </p>

    <h4>Fehlererklärung</h4>
    <h5>Lesewerkzeug nicht initialisiert</h5>
    <p>
      Wenn Sie das Lesewerkzeug verwenden möchten, müssen Sie dieses für die Verwendung zuerst vorbereiten. Dieser Fehler gibt an, wie oft Sie versucht haben, das Lesewerkzeug zu verwenden, ohne dass Sie dieses initialisiert, also für den weiteren Gebrauch betriebsbereit gemacht, haben.
    </p>
    <h5>Merkwerkzeug nicht initialisiert</h5>
    <p>
      Wenn Sie das Merkwerkzeug verwenden möchten, müssen Sie dieses für die Verwendung zuerst vorbereiten. Dieser Fehler gibt an, wie oft Sie versucht haben, das Merkwerkzeug zu verwenden, ohne dass Sie dieses initialisiert, also für den weiteren Gebrauch betriebsbereit gemacht, haben.
    </p>
    <h5>Tausch fehlgeschlagen</h5>
    <p>
      Für einen Tausch sind immer zwei Karten notwendig. Dieser Fehler zeigt Ihnen, wie oft Sie einen Tausch durchführen wollten, obwohl Sie dafür keine Karte vorgemerkt haben.
    </p>
    <h5>Fixieren fehlgeschlagen</h5>
    <p>
      Karten zu fixieren war ein wichtiger Schritt zur Lösung dieser Aufgabe. Dieser Fehler gibt an, wie oft Sie versucht haben, Karten zu fixieren, obwohl alle bereits fixiert waren.
    </p>
    <h5>Lesen oder Merken einer ungültigen Karte</h5>
    <p>
      Dieser Fehler zeigt Ihnen, wie oft Sie mit dem Lese- oder Merkzeiger auf eine Karte zugreifen wollten, die nicht existiert. Das bedeutet, dass Sie die Karte an der Position 0 oder an der Position 6 lesen beziehungsweise merken wollten, obwohl es Karten nur an den Positionen 1 bis 5 gab.
    </p>
    <h5>Unvollständiger Algorithmus</h5>
    <p>
      Mit diesem Fehler wird erfasst, wie oft Sie versucht haben, einen unvollständigen Algorithmus auszuführen. 
    </p>
    <h5>Falsche Lösung</h5>
    <p>
      Dieser Fehler zeigt Ihnen, wie oft Sie einen Algorithmus mit einer falsch sortierten Lösung durchlaufen haben.
    </p>
    <div class="btn-container">
      <div class="custom-btn-secondary" @click="this.experimentBackClicked()">
        Zurück
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  emits: ["experiment-back"],
  data() {
    return {
      sortingA1ExampleImg: require("@/assets/inf/results/sorting_a1_example.png"),
      sortingA2ExampleImg: require("@/assets/inf/results/sorting_a2_example.png"),
      mySortingResultsA1: {},
      mySortingResultsA2: {},
      dataReady: false,
    };
  },
  async mounted() {
    await this.getMyResultsSorting();
    this.dataReady = true;
  },
  methods: {
    async getMyResultsSorting() {
      try {
        const response = await fetch(
          process.env.VUE_APP_STRAPI_API_URL +
            "/api/inf-sorting-a1-log/myResults",
          {
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem("jwt")}`,
            },
          }
        );
        const res = await response.json();
        if (!res || res.length == 0) {
          this.mySortingResultsA1 = "NO RESULTS FOUND";
        } else if (res.length > 1) {
          console.error("THERE ARE SEVERAL RESULTS FOR THIS USER");
          this.mySortingResultsA1 = res;
        } else {
          this.mySortingResultsA1 = res;
        }
      } catch (error) {
        console.log(error);
      }

      try {
        const response = await fetch(
          process.env.VUE_APP_STRAPI_API_URL +
            "/api/inf-sorting-a2-log/myResults",
          {
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem("jwt")}`,
            },
          }
        );
        const res = await response.json();
        if (!res || res.length == 0) {
          this.mySortingResultsA2 = "NO RESULTS FOUND";
        } else if (res.length > 1) {
          console.error("THERE ARE SEVERAL RESULTS FOR THIS USER");
          this.mySortingResultsA2 = res;
        } else {
          this.mySortingResultsA2 = res;
        }
      } catch (error) {
        console.log(error);
      }
    },
    loadResultsIntoInputSorting() {
      document.getElementById("sorting-assign1-results").value = JSON.stringify(
        this.mySortingResultsA1
      );
      document.getElementById("sorting-assign2-results").value = JSON.stringify(
        this.mySortingResultsA2
      );
    },
    returnDate(date) {
      let day = new Date(date);
      const m = ["Januar", "Februar", "März", "April", "Mai", "Juni",
      "Juli", "August", "September", "Oktober", "November", "Dezember"];
      const str_op = day.getDate() + '. ' + m[day.getMonth()] + ' ' + day.getFullYear();
      return str_op;
    },
    returnTime(date) {
      let day = new Date(date).toTimeString();
      //const str_op = day.getHours() + ':' + day.getMinutes();
      return day.substring(0,5);
    },
    experimentBackClicked() {
      this.$emit("experiment-back");
    },
    getSortingA1SolutionPath() {
      switch (this.mySortingResultsA1.solutionPath) {
        case 1:
          return "links-lesenden und links-fixierenden";
        case 2:
          return "links-lesenden und rechts-fixierenden";
        case 3:
          return "rechts-lesenden und links-fixierenden";
        case 4:
          return "rechts-lesenden und rechts-fixierenden";
        default:
          console.error("NO VALID SOLUTION PATH FOUND");
          return "KEIN GÜLTIGER LÖSUNGSWEG GEFUNDEN";
      }
    },
    getSortingA1TotalCorrect() {
      let totalRead =
        this.mySortingResultsA1.firstQuarter.correctActions.correctRead +
        this.mySortingResultsA1.secondQuarter.correctActions.correctRead +
        this.mySortingResultsA1.thirdQuarter.correctActions.correctRead +
        this.mySortingResultsA1.fourthQuarter.correctActions.correctRead;
      let totalSave =
        this.mySortingResultsA1.firstQuarter.correctActions.correctSave +
        this.mySortingResultsA1.secondQuarter.correctActions.correctSave +
        this.mySortingResultsA1.thirdQuarter.correctActions.correctSave +
        this.mySortingResultsA1.fourthQuarter.correctActions.correctSave;
      let totalFix =
        this.mySortingResultsA1.firstQuarter.correctActions.correctFix +
        this.mySortingResultsA1.secondQuarter.correctActions.correctFix +
        this.mySortingResultsA1.thirdQuarter.correctActions.correctFix +
        this.mySortingResultsA1.fourthQuarter.correctActions.correctFix;
      let totalSwap =
        this.mySortingResultsA1.firstQuarter.correctActions.correctSwap +
        this.mySortingResultsA1.secondQuarter.correctActions.correctSwap +
        this.mySortingResultsA1.thirdQuarter.correctActions.correctSwap +
        this.mySortingResultsA1.fourthQuarter.correctActions.correctSwap;

      return {
        read: totalRead,
        save: totalSave,
        fix: totalFix,
        swap: totalSwap,
        total: totalRead + totalSave + totalFix + totalSwap,
      };
    },
    getSortingA1TotalWrong() {
      let totalRead =
        this.mySortingResultsA1.firstQuarter.wrongActions.wrongRead +
        this.mySortingResultsA1.secondQuarter.wrongActions.wrongRead +
        this.mySortingResultsA1.thirdQuarter.wrongActions.wrongRead +
        this.mySortingResultsA1.fourthQuarter.wrongActions.wrongRead;
      let totalSave =
        this.mySortingResultsA1.firstQuarter.wrongActions.wrongSave +
        this.mySortingResultsA1.secondQuarter.wrongActions.wrongSave +
        this.mySortingResultsA1.thirdQuarter.wrongActions.wrongSave +
        this.mySortingResultsA1.fourthQuarter.wrongActions.wrongSave;
      let totalFix =
        this.mySortingResultsA1.firstQuarter.wrongActions.wrongFix +
        this.mySortingResultsA1.secondQuarter.wrongActions.wrongFix +
        this.mySortingResultsA1.thirdQuarter.wrongActions.wrongFix +
        this.mySortingResultsA1.fourthQuarter.wrongActions.wrongFix;
      let totalSwap =
        this.mySortingResultsA1.firstQuarter.wrongActions.wrongSwap +
        this.mySortingResultsA1.secondQuarter.wrongActions.wrongSwap +
        this.mySortingResultsA1.thirdQuarter.wrongActions.wrongSwap +
        this.mySortingResultsA1.fourthQuarter.wrongActions.wrongSwap;

      return {
        read: totalRead,
        save: totalSave,
        fix: totalFix,
        swap: totalSwap,
        total: totalRead + totalSave + totalFix + totalSwap,
      };
    },
    getSortingA2TotalErrors(){
      return this.mySortingResultsA2.usedReadWithoutSettingReadErrors + this.mySortingResultsA2.usedSaveWithoutSettingSaveErrors + this.mySortingResultsA2.noCardSavedBeforeSwapErrors + this.mySortingResultsA2.fixedAllCardsErrors + this.mySortingResultsA2.readOutOfBoundsErrors + this.mySortingResultsA2.finishedWithWrongOrderErrors;
    },
    getBackgroundColor(fehler){
      switch (fehler) {
        case 0:
          return "#00FF00";
        case 1:
          return "#FCE7E7";
        case 2:
          return "#FFC9C8";
        case 3:
          return "#FFAAA9";
        default:
          return "#FF6868";
      }
    }
  },
};
</script>

<style scoped>
.additional-info-italic {
  font-style: italic;
}

.red {
  background-color: red;
}

.custom-btn,
.custom-btn-secondary {
  float: right;
}
</style>
